import * as React from "react";
import { PageProps, graphql } from "gatsby";

import Layout from "../components/layout";
import { useTranslation } from "react-i18next";
import { useI18next } from "gatsby-plugin-react-i18next";
import { CommonFunctions } from "../components/pageFragments/functions";

const Home: React.FC<PageProps> = ({ location }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  return (
    <Layout
      seoTitle={t("home.htmlTitle")}
      seoDescription={t("home.header.title")}
      isLandingPage
      location={location}
      headerContent={
        <div className="header-content">
          <div className="row">
            <div className="col-md-5">
              <h1>{t("home.header.title")}</h1>
              <p className="text-dark mb-4">{t("home.header.description")}</p>
              <a href="#reasons" className="btn btn-primary mb-4">
                {t("home.header.learnMoreButton")}
              </a>
            </div>
            <div className="col-md-7">
              <img
                src={`/images/home/header_${language}.png`}
                alt="app"
                width="688px"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      }
    >
      <>
        <section className="ins-page-section text-center">
          <div className="container">
            <h2 className="section-title" id="reasons">
              {t("home.section.applicationForManaging.headerPart1")}{" "}
              <span className="text-primary">
                {t("home.section.applicationForManaging.headerPart2")}
              </span>
            </h2>
            <div className="row mt-5">
              <div className="col-md-4 ins-feature">
                <img
                  src="/images/home/features/features-control.png"
                  alt="icon"
                  className="feature-icon"
                />
                <h5 className="feature-title">
                  {t("home.section.applicationForManaging.feature1.header")}
                </h5>
                <p className="feature-content">
                  {t("home.section.applicationForManaging.feature1.content")}
                </p>
                <a href="#start-day-with-inspecto" className="feature-link">
                  {t("home.section.applicationForManaging.moreButton")}
                </a>
              </div>
              <div className="col-md-4 ins-feature">
                <img
                  src="/images/home/features/features-money.png"
                  alt="icon"
                  className="feature-icon"
                />
                <h5 className="feature-title">
                  {t("home.section.applicationForManaging.feature2.header")}
                </h5>
                <p className="feature-content">
                  {t("home.section.applicationForManaging.feature2.content")}
                </p>
                <a href="#start-day-with-inspecto" className="feature-link">
                  {t("home.section.applicationForManaging.moreButton")}
                </a>
              </div>
              <div className="col-md-4 ins-feature">
                <img
                  src="/images/home/features/features-calm.png"
                  alt="icon"
                  className="feature-icon"
                />
                <h5 className="feature-title">
                  {t("home.section.applicationForManaging.feature3.header")}
                </h5>
                <p className="feature-content">
                  {t("home.section.applicationForManaging.feature3.content")}
                </p>
                <a href="#start-day-with-inspecto" className="feature-link">
                  {t("home.section.applicationForManaging.moreButton")}
                </a>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="landing-page-video-wrapper">
            <video controls autoPlay muted>
              <source src="/files/landing-page-video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <section className="ins-page-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6 order-md-2">
                <h2 className="section-title" id="start-day-with-inspecto">
                  {t("home.section.startDayWith.headerPart1")}{" "}
                  <span
                    className="text-primary"
                    dangerouslySetInnerHTML={{
                      __html: t("home.section.startDayWith.headerPart2"),
                    }}
                  ></span>
                </h2>
                <p className="mb-5">{t("home.section.startDayWith.content")}</p>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="media landing-feature">
                      <span className="landing-feature-icon"></span>
                      <div className="media-body">
                        <h5>
                          {t("home.section.startDayWith.feature1.header")}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="media landing-feature">
                      <span className="landing-feature-icon"></span>
                      <div className="media-body">
                        <h5>
                          {t("home.section.startDayWith.feature2.header")}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="media landing-feature">
                      <span className="landing-feature-icon"></span>
                      <div className="media-body">
                        <h5>
                          {t("home.section.startDayWith.feature3.header")}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-md-0">
                <img
                  src={`/images/home/vehicle-state_${language}.png`}
                  alt="Status pojazdu"
                  className="img-fluid"
                  width="492px"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="ins-page-section">
          <div className="container">
            <h4
              className="section-title mb-4 text-center"
              dangerouslySetInnerHTML={{
                __html: t("aboutUs.innovationSection.companyLogosHeader"),
              }}
            />
            <div className="d-flex flex-wrap justify-content-center">
              <div className="clients-logo">
                <img
                  src="/images/home/company-logos/gruber.png"
                  alt="gruber"
                  className="img-fluid"
                />
              </div>
              <div className="clients-logo clients-logo--wide">
                <img
                  src="/images/home/company-logos/omega-pilzno.png"
                  alt="omega-pilzno"
                  className="img-fluid"
                />
              </div>
              <div className="clients-logo">
                <img
                  src="/images/home/company-logos/freja.png"
                  alt="freja"
                  className="img-fluid"
                />
              </div>
              <div className="clients-logo">
                <img
                  src="/images/home/company-logos/maszonski.png"
                  alt="maszonski"
                  className="img-fluid"
                />
              </div>
              <div className="clients-logo">
                <img
                  src="/images/home/company-logos/budmat.png"
                  alt="budmat"
                  className="img-fluid"
                />
              </div>
              <div className="clients-logo clients-logo--wide">
                <img
                  src="/images/home/company-logos/legarto.jpg"
                  alt="legarto"
                  className="img-fluid"
                />
              </div>
              <div className="clients-logo clients-logo--tall">
                <img
                  src="/images/home/company-logos/vive.png"
                  alt="vive"
                  className="img-fluid"
                />
              </div>
              <div className="clients-logo clients-logo--wide">
                <img
                  src="/images/home/company-logos/citronex.png"
                  alt="citronex"
                  className="img-fluid"
                />
              </div>
              <div className="clients-logo clients-logo--wide">
                <img
                  src="/images/home/company-logos/omegatransport.svg"
                  alt="omegatransport"
                  className="img-fluid"
                />
              </div>
              <div className="clients-logo clients-logo--wide">
                <img
                  src="/images/home/company-logos/sachstrans.png"
                  alt="sachstrans"
                  className="img-fluid"
                />
              </div>
              <div className="clients-logo clients-logo--tall">
                <img
                  src="/images/home/company-logos/natanek.png"
                  alt="natanek"
                  className="img-fluid"
                />
              </div>
              <div className="clients-logo">
                <img
                  src="/images/home/company-logos/galan.png"
                  alt="galan"
                  className="img-fluid"
                />
              </div>
              <div className="clients-logo">
                <img
                  src="/images/home/company-logos/antrans.png"
                  alt="antrans"
                  className="img-fluid"
                />
              </div>
              <div className="clients-logo">
                <img
                  src="/images/home/company-logos/transkrusz.jpeg"
                  alt="transkrusz"
                  className="img-fluid"
                />
              </div>
              <div className="clients-logo clients-logo--wide">
                <img
                  src="/images/home/company-logos/adecon.png"
                  alt="adecon"
                  className="img-fluid"
                />
              </div>
              <div className="clients-logo clients-logo--wide">
                <img
                  src="/images/home/company-logos/magtrans.png"
                  alt="magtrans"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="ins-page-section text-center">
          <div className="container">
            <h2 className="section-title">
              {t("home.section.howThatWorks.header")}
            </h2>
            <div className="row mt-5">
              <div className="col-md-4 ins-feature">
                <img
                  src="/images/home/how-it-works/1-protocol.png"
                  alt="icon"
                  className="feature-icon-big"
                />
                <h5 className="feature-title">
                  1. {t("home.section.howThatWorks.step1.header")}
                </h5>
                <p className="feature-content">
                  {t("home.section.howThatWorks.step1.description")}
                </p>
              </div>
              <div className="col-md-4 ins-feature">
                <img
                  src="/images/home/how-it-works/2-not-ok.png"
                  alt="icon"
                  className="feature-icon-big"
                />

                <h5 className="feature-title">
                  2. {t("home.section.howThatWorks.step2.header")}
                </h5>
                <p
                  className="feature-content"
                  dangerouslySetInnerHTML={{
                    __html: t("home.section.howThatWorks.step2.description"),
                  }}
                />
              </div>
              <div className="col-md-4 ins-feature">
                <img
                  src="/images/home/how-it-works/3-fixing.png"
                  alt="icon"
                  className="feature-icon-big"
                />
                <h5 className="feature-title">
                  3. {t("home.section.howThatWorks.step3.header")}
                </h5>
                <p
                  className="feature-content"
                  dangerouslySetInnerHTML={{
                    __html: t("home.section.howThatWorks.step3.description"),
                  }}
                />
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-md-4 ins-feature">
                <img
                  src="/images/home/how-it-works/4-ok.png"
                  alt="icon"
                  className="feature-icon-big"
                />
                <h5 className="feature-title">
                  4. {t("home.section.howThatWorks.step4.header")}
                </h5>
                <p
                  className="feature-content"
                  dangerouslySetInnerHTML={{
                    __html: t("home.section.howThatWorks.step4.description"),
                  }}
                />
              </div>
              <div className="col-md-4 ins-feature">
                <img
                  src="/images/home/how-it-works/5-ready.png"
                  alt="icon"
                  className="feature-icon-big"
                />
                <h5 className="feature-title">
                  5. {t("home.section.howThatWorks.step5.header")}
                </h5>
                <p
                  className="feature-content"
                  dangerouslySetInnerHTML={{
                    __html: t("home.section.howThatWorks.step5.description"),
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-12 lead text-center mt-5"
                dangerouslySetInnerHTML={{
                  __html: t("home.section.howThatWorks.underline"),
                }}
              />
            </div>
          </div>
        </section>
        <section className="ins-page-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-5 order-md-2">
                <h2 className="about-section-two-title">
                  {t("home.section.makesTasksSimple.headerPart1")}{" "}
                  <span className="font-weight-normal">
                    {t("home.section.makesTasksSimple.headerPart2")}
                  </span>
                </h2>
                <div className="about-section-two-content">
                  <p>{t("home.section.makesTasksSimple.description1")}</p>
                  <p>{t("home.section.makesTasksSimple.description2")}</p>
                </div>
              </div>
              <div className="col-md-6 mb-md-0">
                <img
                  src="/images/home/truck-table.jpeg"
                  alt="Ułatwia realizację codziennych zadań"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
        <CommonFunctions />
      </>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
